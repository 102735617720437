<template>
	<div>
		<b-form
			@submit.prevent="uploadFile"
			@change="clearError($event.target.file);">
			<slot name="label">
				<label>
					{{ translate('file') }} <span class="text-danger">*</span>
				</label>
			</slot>
			<b-form-file
				id="file"
				ref="file"
				:required="true"
				:placeholder="translate('no_file')"
				:browse-text="translate('browse')"
				:class="hasError('file') ? 'is-invalid' : ''"
				class="text-truncate"
				name="file"
				type="file" />
			<b-form-text
				id="file"
				style="margin-bottom: 15px;">
				{{ translate('max', {size: '5'}) }} {{ translate('allowed_ext', {ext: fileExt }) }}
			</b-form-text>
			<b-button
				:disabled="Object.keys(errors).length > 0 || loading"
				variant="primary"
				size="md"
				class="mr-2"
				type="submit">
				<template v-if="loading">
					<i class="fa fa-fw fa-spinner fa-pulse" />
					{{ translate('processing') }}
				</template>
				<template v-else>
					{{ translate('submit') }}
				</template>
			</b-button>
			<b-button
				class="mr-2"
				variant="light"
				size="md"
				@click="$router.push({ name: 'OrdersSettlements' })">
				{{ translate('cancel') }}
			</b-button>
		</b-form>
	</div>
</template>
<script>

import WindowSizes from '@/mixins/WindowSizes';
import {
	AMAZON_FILES_EXT as fileExt,
} from '@/settings/Order';
import { UserFiles, Settlement as SettlementMessages } from '@/translations';

import Order from '@/util/Order';

export default {
	name: 'UploadFileForm',
	messages: [UserFiles, SettlementMessages],
	components: {},
	mixins: [WindowSizes],
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		errorsImport: {
			type: Object,
			default() {
				return {};
			},
		},
		formImport: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			form: this.formImport,
			errors: this.errorsImport,
			fileExt,
			order: new Order(),
			alert: new this.$Alert(),
		};
	},
	watch: {
		errorsImport(value) {
			this.errors = value;
		},
	},
	methods: {
		uploadFile() {
			const formData = new FormData();
			this.form.file = undefined;
			if (typeof this.$refs.file !== 'undefined') {
				const [File] = this.$refs.file.$refs.input.files;
				this.form.file = File;
			}
			// Required fields
			formData.set('file', this.form.file);
			this.fileInfoSubmit(formData);
		},
		fileInfoSubmit(formData) {
			this.order.uploadSettlement(formData).then(() => {
				this.alert.toast('success', this.translate('file_uploaded'));
				this.$router.push({ name: 'OrdersSettlements' });
			}).catch((err) => {
				this.errors = { ...err.errors };
			}).finally(() => { this.loading = false; });
		},
		hasError(field) {
			if (typeof this.errors[field] !== 'undefined') { return true; }
			return false;
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors = { ...this.errors };
			}
		},
	},
};
</script>
